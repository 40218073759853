@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;1,100&display=swap");
* {
  font-family: "Poppins", sans-serif !important;
}

.App {
  text-align: center;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-banner,
.btn {
  background-color: #ff5100 !important;
}

.conversation .conversation-content .conversation-item.actived {
  background: linear-gradient(270deg, #ff5100 0%, #e64900 100%) !important;
}

.text-area {
  background: #fff !important;
  margin: 0 21px;
  border-radius: 8px;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.01);
  width: 94% !important;

  padding: 7px 12px !important;
  height: 70px !important;
}

/* 
 
* customizing as only one person can chat at one time
* by hiding chat list and chat persons list

*/

.conversation .top .item {
  display: none;
}

.chat {
  width: 100% !important;
}

.chat .send-box .text-area {
  margin: 0px 18px;
}

.chat .send-box .send-button {
  margin-left: auto;
  margin-right: auto;
}

.chat .send-box {
  height: 180px;
}

.conversation {
  display: none;
}

@media (max-width: 480px) {
  .startup-model {
    min-width: 285px;
  }

  .startup-model img {
    max-width: 184px;
  }

  .chat .send-box .text-area {
    margin: 0px 12px;
  }
}
